.root {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 32px);
}

.subRoot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.buttonYes{
    background-color: #0092D5;
    display: block;
    color: white;
    text-shadow: 1px 1px #0a0810;
    font-size: xx-large;
    font-weight: bolder;
    padding: 10px 25px;
    margin: 30px 0 10px 0;
    border-radius: 3px;
    border: 1px solid rgba(80, 80, 80, 0.97);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
}