.root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: black;
    box-sizing: border-box;
    padding: 10px 20px;
}

.headerButton {
    background-color: white;
    font-size: x-large;
    border-radius: 4px;
    border: none;
    margin: 5px;
    padding: 5px;
}

.getItButton {
    background-color: green;
    font-size: x-large;
    border-radius: 4px;
    border: none;
    margin: 5px;
    color: white;
    padding: 5px;
}