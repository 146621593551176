.spinner {
    opacity: 1;
    transition: opacity linear 0.1s;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 2s linear infinite;
    border: solid 5px #eee;
    border-bottom-color: #ffc627;
    border-radius: 50%;
    will-change: transform;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 150;
}

.spinnerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
}