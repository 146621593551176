.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.videoBackground {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.75);
    width: 60%;
}
.mobileHeader{
    display: none;
}
.yellowBanner {
    background-color: #ffc627;
}

.bannerText {
    font-size: xx-large;
    font-weight: bold;
}

.bannerBox {
    display: flex;
    margin-top: 1%
}

.bannerText span {
    text-decoration: underline;
}

.subHeader {
    color: grey;
    font-size: 28px;
    margin-bottom: 3px;
}

.mainBg {
    width: 100%;
    background-image: url("../../assets/images/collagebg.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.learnMore {
    color: #ffc627;
    font-size: 18px;
    margin-bottom: 3px;
}

.videoWrapper {
    border: solid 4px white;
    border-radius: 4px;
}

.videoAlign {
    display: flex;
}

.titleRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1000px;
}

.titleText {
    color: white;
    font-size: 59px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: bolder;
    width: 100%;
}

.discountAmount {
    font-size: 50px !important;
}

.titleText span {
    font-size: 42px;
    color: #ffc627;

}

.discount {
    font-size: 46px;
}

.specialOffer {
    margin: 16px auto 10px auto;
    padding: 5px 65px;
    font-size: 32px;
    background-color: rgb(243, 187, 43);
    color: rgb(45, 45, 45);
    font-weight: 700;
}

.specialOffer span {
    text-decoration: underline;
}

.offerWhite {
    font-size: 28px;
    color: white;
    font-weight: 700;
    margin: auto;
    padding-top: 1%;
    padding-bottom: 14px;
}

.offerWhiteTwo {
    font-size: 28px;
    color: white;
    font-weight: 700;
    padding-bottom: 14px;
    margin-top: -2%;
    margin-bottom: 0;
}

.greenPulse {
    padding: 5px 20px;
    width: 50%;
    border: 1px solid #ffc627;
    color: #0A0A0A;
    margin-bottom: 15px;
    font-size: 46px;
    background-color: #ffc627;
    box-sizing: border-box;
    font-weight: bolder;
    border-bottom: 5px solid #c18b26;
    border-radius: 8px;
}

.greenPulse:hover {
    cursor: pointer;
    transform: scale(1.05, 1.05);
}

.buttonSubtext {
    font-size: medium;
    font-weight: normal;
}

.floatButtonBox {
    height: 70px;
    width: 100%;
    position: fixed;
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #0A0A0A;
}

.floatButton {
    background-color: #ffc627;
    border-bottom: 5px solid #db9e00;
    width: 90%;
    height: 70%;
    border-radius: 8px;
    font-size: x-large;
    font-weight: bolder;
    -webkit-animation: floatPulse 1s ease-in-out infinite alternate;
    -moz-animation: floatPulse 1s ease-in-out infinite alternate;
    animation: floatPulse 1s ease-in-out infinite alternate;
    user-select: none;
}

.floatPlaceholder {
    height: 100px;
    display: none;
}

@-webkit-keyframes floatPulse {
    from {
        transform: scale(0.9, 0.9);
    }
    to {
        transform: scale(1, 1);
    }
}

@-webkit-keyframes greenPulse {
    from {
        -webkit-box-shadow: 0 0 8px #ffc627;
    }
    50% {
        -webkit-box-shadow: 0 0 1px #fff114;
    }
    to {
        -webkit-box-shadow: 0 0 7px #ffc627;
    }
}

.ctaBorder {
    margin-top: 200px;
}

@media (orientation: portrait) {
    .mainBg {
        background-size: auto 100%;
    }
}

@media (max-width: 1100px) {

    .videoBackground {
        width: 95%;
    }

    .titleText {
        font-size: x-large;
    }

    .bannerText {
        font-size: large;
        font-weight: bold;
    }

    .titleText span {
        font-size: xx-large;
        color: #ffc627;
    }

    .subHeader {
        font-size: x-large;
    }

    .greenPulse {
        font-size: xx-large;
        width: 90%;
    }

    .buttonSubtext {
        font-size: x-small;
        font-weight: normal;
    }

    .offerWhite {
        font-size: x-large;
    }
}

@media (max-width: 900px) {
    .desktopHeader {
        display: none;
    }
.mobileHeader{
    display: flex;
}
    .floatButtonBox {
        display: flex;
    }

    .floatPlaceholder {
        display: block;
    }

    .videoBackground {
        width: 100%;
    }

    .videoWrapper {
        border: none;
    }

    .titleText {
        font-size: xx-large;
        width: 90%;
        line-height: 1em;
        margin-bottom: 30px;
    }

    .subHeader {
        font-size: large;
        margin-top: 10px;
        margin-bottom: -20px;
    }

    .learnMore {
        font-size: medium;
        font-weight: 100;
        margin-bottom: 10px;
    }

    .greenPulse {
        font-size: x-large;
        width: 90%;
    }

    .offerWhite {
        font-size: large;
        width: 95%;
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .titleText {
        width: 100%;
    }
}
