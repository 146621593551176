.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    border: 2px solid #DDD;
    border-radius: 4px;
    background-color: white;
}

.progressRow {
    display: flex;
    flex-direction: row;
    background-image: linear-gradient(to bottom, white, #f3f3f3);
    border-bottom: 3px solid green;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align: center;
    box-sizing: border-box;
}

.quickTwoStep{
    font-size: xx-large;
    color: green;
    font-weight: 900;
}
.stepOne{
}
.stepTwo{
    font-size: xx-large;
    color: green;
    font-weight: 900;
    text-align: center;
    align-content: center;
    align-self: center;
}
.submitOne{
    width: 90%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: rgba(0, 128, 0, 0.52);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}

.submitMain{
    width: 100%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: green;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}
.stepOneBox{
    display: flex;
    flex-direction: column;
    margin-top: 2%;

}
.progressBlock {
    display: flex;
    flex-direction: row;
    width: 85%;
    justify-content: center;
}

.stepNumber {
    font-size: x-large;
    font-weight: bolder;
    padding: 5px;
    text-align: center;

}

.stepText {
    display: flex;
    flex-direction: column;
    text-align: center;

    padding: 5px;
}

.stepTitle {
    margin: 0;
    font-weight: bold;
}

.stepTitleActive {
    color: green;
}

.stepSubtitle {
    margin: 0;
    font-size: small;
}

.phoneProgress {
    display: none;
}

.phoneProgress h2 {
    margin: 10px 0;
}

@media (max-width: 800px) {
    .progressRow {
        display: none;
    }

    .phoneProgress {
        display: flex;
    }
}