.root {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url(https://s30745.pcdn.co/wp-content/uploads/2020/01/image.png);
    width: 100%;
    padding: 40px 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.subRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title {
    color: black;
    font-size: x-large;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: bolder;
    text-transform: uppercase;
}
.text{
    font-size: x-large;
    font-weight: 500;
    width: 95%;
    margin: 0 auto 1em auto;
}

.button {
    background-color: #0092D5;
    display: block;
    color: white;
    text-shadow: 1px 1px #0a0810;
    font-size: xx-large;
    font-weight: bolder;
    padding: 10px 25px;
    margin: 30px 0 10px 0;
    border-radius: 3px;
    border: 1px solid rgba(80, 80, 80, 0.97);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
}
.button:hover {
    cursor: pointer;
    color: #f3bb2b;
}

.button span {
    font-size: 14px;
    display: block;
    font-weight: normal;
    text-shadow: 1px 1px #0a0810;
}

.mobile {
    display: none;
}


.button h3 {
    margin: 1px 0;
}

.button p {
    margin: 1px 0;
}

@media (max-width: 1500px) {
    .subRoot {
        width: 90%;
    }
}

@media (max-width: 1100px) {
    .subRoot {
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 95%;
    }

    .title {
        font-size: x-large;
        margin-bottom: 30px;
        line-height: 1.1em;
    }

    .right {
        width: 95%;
    }

    .mobile {
        display: flex;
    }

    .button {
        font-size: larger;
        width: 90%;
        margin-top: 20px;
        display: none;
    }

    .nonMobile {
        display: none;
    }
}
