.root {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(245, 245, 245);
    width: 100%;
    padding: 40px 0;
}

.subRoot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title {
    color: black;
    font-size: x-large;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.button {
    background-color: #ffc627;
    border-radius: 3px;
    width: 80%;
    font-size: x-large;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mobile {
    display: none;
}

.button:hover {
    cursor: pointer;
    background-color: #ffd250;
}

.button h3 {
    margin: 1px 0;
}

.button p {
    margin: 1px 0;
}

@media (max-width: 1500px) {
    .subRoot {
        width: 90%;
    }
}

@media (max-width: 1100px) {
    .subRoot {
        flex-direction: column;
        align-items: center;
        align-content: center;
        width: 95%;
    }

    .title {
        font-size: x-large;
        margin-bottom: 30px;
        line-height: 1.1em;
    }

    .right {
        width: 95%;
    }

    .mobile {
        display: flex;
    }

    .button {
        font-size: larger;
        width: 90%;
        margin-top: 20px;
        display: none;
    }

    .nonMobile {
        display: none;
    }
}