.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/collagebg.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
}

.backgroundOverlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
}
.bonusesRow{
    display: flex;
    flex-direction: row;
}
.subRoot {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    width: 70%;
    z-index: 2;
}

.block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 520px;
}

.left {
    color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.right {
    /*background-color: white;*/
    background-color: rgba(0, 0, 0, 0.75);
    border: 2px solid white;
    border-radius: 1px;
    padding: 20px;
    box-sizing: border-box;
    width: 70%;
}

.rightInfoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-in; /* Safari */
    transition: max-height 0.5s ease-in;
    max-height: 0;
}

.activeRightInfoBlock {
    max-height: 800px;
}

.title {
    color: #ffc627;
    font-weight: 700;
    font-size: 46px;
    max-width: 80%;
    /*
    border-bottom: 3px solid white;
    */
    box-sizing: border-box;
    z-index: 2;
    text-decoration-color: white;
}

.mobileTitle {
    color: #ffc627;
    font-weight: 700;
    font-size: xx-large;
    width: 90%;
    box-sizing: border-box;
    z-index: 2;
    display: none;
}

.imageBlock {
    background-color: white;
    padding: 2px;
    width: 45%;
    border-radius: 1px;
    margin: 0 10px 15px 10px;
    box-sizing: border-box;
}

.image {
    width: 100%;
    display: flex;
}

.priceBlock {
    background-color: #0A0A0A;
    color: black;
    width: 100%;
    padding: 3px 0;
    margin-bottom: 15px;
    box-sizing: border-box;
    border: 1px solid #ffc627;
}

.priceBlock p {
    font-size: 32px;
    margin: 0;
    font-weight: bold;
    color: white;
}

.bonusesTitle {
    border-top: 3px solid white;
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
    font-weight: 700;
    font-size: 30px;
    margin: 0;
}

.check {
    margin-right: 5px;
    color: #ffc627;
    font-weight: 700;
    font-size: inherit;
}

.checkText {
    font-size: 32px;
    font-weight: 700;
}

.whatYouGet {
    font-size: 32px;
    border-top: 1px solid rgba(61, 59, 60, 55);
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0;
    margin: 10px 0;
}

.rightTitle {
    font-size: xx-large;
    font-weight: bolder;
    margin: 0;
    padding-bottom: 20px;
    color: #ffc627;
}

.sevenDayTrial {
    font-size: xx-large;
    color: white;
    font-weight: bolder;
    margin: 15px 0 5px 0;
}

.rightLarge {
    font-size: xx-large;
    color: white;
    margin: 0;
}

.rightMiddle {
    font-size: x-large;
    color: white;
    margin: 0;
}

.rightSmall {
    color: white;
    margin: 0;
}

.rightList {
    margin: 20px 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.rightListItem {
    list-style: none;
    color: white;
    font-size: large;
    font-weight: bolder;
    margin-bottom: 10px;
}

.rightListItem p {
    margin: 0;
}

.boxed {
    border: 3px dashed #ffc627;
    box-sizing: border-box;
    padding: 10px;
}

.backButton {
    position: absolute;
    top: 5px;
    left: 5px;
    border: none;
    font-size: large;
    background-color: #0A0A0A;
    color: #ffc627;
    z-index: 200;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px;
}

.backButton:hover {
    background-color: #ffc627;
    color: #0A0A0A;
    cursor: pointer;
}

.backButtonIcon {
    border: 1px solid #ffc627;
}

.backButtonText {
    margin-left: 5px;
}

.backButtonPlaceholder {
    height: 30px;
    width: 20px;
    display: none;
}

.timerBox {
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    right: 10px;
    bottom: 10px;
    color: red;
    font-size: x-large;
}

.timerPlaceholder {
    height: 90px;
    width: 100%;
    display: none;
}

@media (max-width: 1100px) {
    .right {
        width: 100%;
    }
}

@media (max-width: 800px) {
    .root {
        background-size: auto 100%;
    }

    .backButton {
        font-size: xx-large;
    }

    .backButtonText {
        display: none;
    }

    .backButtonPlaceholder {
        display: block;
    }

    .timerBox {
        right: auto;
        width: 100%;
        bottom: 0;
        background-color: #0A0A0A;
        z-index: 300;
    }

    .timerPlaceholder {
        display: block;
    }

    .subRoot {
        flex-direction: column;
        align-items: center;
    }

    .right {
        order: 1;
        padding: 0;
    }

    .left {
        order: 2;
    }

    .title {
        display: none;
    }

    .mobileTitle {
        display: block;
    }

    .rightMiddle, .rightLarge, .rightSmall, .sevenDayTrial, .rightList, .rightTitle {
        display: none;
    }

    .rightTitle {
        font-size: large;
    }

    .imageBlock {
        width: 95%;
    }
}

@media (max-width: 600px) {
    .subRoot {
        width: 95%;
    }

    .block {
        width: 90%;
    }
}

@media (max-width: 500px) {
    .subRoot {
        flex-direction: column;
        align-items: center;
        width: 95%;
    }

    .block {
        margin: 20px 5px;
        width: 100%;
    }
}
