.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #222940;
    min-height: 100vh;
    color: white;
}

.subRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: black;
}

.title {
    font-size: xx-large;
}

.dateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.dateContainer input {
    padding: 10px;
    font-size: x-large;
    margin: 5px;
    border-radius: 4px;
}

.dateContainer input::-webkit-inner-spin-button {
    display: none;
}

.dateContainer input::-webkit-clear-button {
    display: none;
}

.dateContainer input::-webkit-calendar-picker-indicator {
    width: 20px;
    height: 20px;
    color: #0092D5;
}

.getButton {
    font-size: x-large;
    font-weight: bold;
    background-color: lightskyblue;
    padding: 10px;
    border: none;
    border-radius: 4px;
}

.aggregatesRoot {
    background-color: white;
    border-radius: 5px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 90%;
    align-items: center;
    justify-content: center;
}

.dataField {
    background-color: green;
    padding: 10px 20px;
    font-size: xx-large;
    margin: 5px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 2px solid black;
    font-weight: bolder;
    color: black;
}

.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-top: 2%;
}

.tab {
    padding: 10px;
    font-size: x-large;
    margin: 5px;
    background-color: white;
    width: 100%;
}

.tab:hover {
    background-color: #ffc627;
}

.activeTab {
    background-color: #0092D5;
}

.dataRoot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.globalSpend{
    color: red;
    font-size: 34px;
    margin-top: 2%;
    width: 97%;

}

@media (max-width: 800px) {
    .dateContainer {
        display: flex;
        flex-direction: column;
    }

    .tabs {
        display: flex;
        flex-direction: column;
    }
}