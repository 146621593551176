.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.queryInput {
    padding: 5px;
    font-size: x-large;
    font-weight: bold;
    margin: 5px;
}

.filter {
    padding: 5px;
    font-size: x-large;
    font-weight: bold;
    margin: 5px;
}

.checkBox {
    border: 4px solid white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.check {
    min-width: 28px;
    min-height: 28px;
    max-width: 100%;
    max-height: 100%;
    color: white;
}

.checkLabel {
    color: white;
    font-size: x-large;
    margin-left: 10px;
    font-weight: bold;
}