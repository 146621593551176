.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.subRoot {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
}

.input {
    font-size: xx-large;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid black;
    margin: 2px;
    width: 100%;
    box-sizing: border-box;
}