.content {
    display: flex;
    flex-direction: column;
}

.toggle-btn {
    margin: 12px 0 0 15px;
    border: none;
    background: black;
    color: white;
    outline: none;
    cursor: pointer;
    font-size: 9px;
}

@keyframes slideIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slideOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
.slide-enter {
    opacity: 0;
}
.slide-enter-active {
    animation-name: slideIn;
    animation-duration: .4s;
}
.slide-exit {
    opacity: 1;
}
.slide-exit-active {
    animation-name: slideOut;
    animation-duration: .4s;
}

@media (min-width: 1199px) {
    .hamburgerMenu {
        display: none;
    }

}

@media (max-width: 1200px) {
    .hamburgerMenu {
        display: block;
    }

    .content {
        position: absolute;
        top: 40px;
        left: 0;
        min-width: 100vw;
        background: black;
        font-size: 1rem;
    }
}