.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
}

.root a {
    color: white;
    margin: 5px;
}