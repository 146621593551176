.root {
    background-color: #0A0A0A;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    background-image: url("../../../assets/images/jordan_stage.jpg");
    background-repeat: no-repeat;
    background-position: 0 -200px;
    background-size: 100% auto;
    padding: 40px 0;
}

.subRoot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    width: 50%;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 60%;
}

.title {
    font-size: 42px;
    color: #ffc627;
}

.body {
    color: white;
    font-size: x-large;
}

.button {
    background-color: rgb(228, 59, 44);
    color: white;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 10px 20px;
    width: 70%;
    margin-top: 40px;
    font-size: x-large;
}

.button:hover {
    cursor: pointer;
    background-color: rgb(255, 60, 44);
}

.button h3 {
    margin: 5px 0;
}

.button p {
    margin: 5px 0;
    font-size: small;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20%;
    box-sizing: border-box;
    visibility: hidden;
}

.imageBox img {
    max-width: 100%;
    max-height: 100%;
}

.mobileSubRoot {
    display: none;
}


@media (max-width: 1100px) {
    .root {
        background-position: 0;
    }

    .subRoot {
        flex-direction: column;
        width: 95%;
    }

    .right {
        width: 90%;
    }

    .title {
        font-size: xx-large;
    }

    .body {
        font-size: larger;
        text-shadow: 0 0 5px black;
        font-weight: bold;
    }

    .button {
        width: 90%;
        margin-top: 20px;
    }

    .button h3 {
        font-size: x-large;
    }
}

@media (orientation: portrait) {
    .root {
        background-size: auto 100%;
        background-position: -200px 0;
    }
}

@media (max-width: 800px) {
    .subRoot {
        display: none;
    }

    .root {
        background-image: none;
        padding: 0;
    }

    .mobileSubRoot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        padding-bottom: 20px;
    }

    .books {
        width: 95%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        margin: 70px 0;
    }

    .book {
        width: 30%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 5px;
        box-shadow: -3px 3px 15px -3px rgba(0, 0, 0, 0.5);
    }

    .bookLeft {
        transform: translateY(-60px);
    }

    .bookRight {
        transform: translateY(60px);
    }

    .image {
        max-width: 100%;
        max-height: 100%;
    }

    .mobileImageBox {
        width: 100%;
    }

    .mobileImageTitle {
        margin-bottom: 5px;
    }
}
