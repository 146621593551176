.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    padding: 20px;
}

.spinnerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.input {
    width: 100%;
    font-size: large;
    border-radius: 5px;
    background-color: #F1F1F1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,0,0,0.05);
    padding: 12px 18px;
    margin-top: 15px;
}

.inputError {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid red;
}

.submit {
    width: 100%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: green;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}

.submit:hover {
    cursor: pointer;
}
.submitOffOne{
    width: 90%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: rgba(0, 128, 0, 0.52);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}
.submitOff{
    width: 100%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: rgba(0, 128, 0, 0.52);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}
.submitStepOneContainer{
    width: 90%;
}
.submitOn{
    width: 90%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: rgba(0, 128, 0, 0.52);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.2);
    margin-top: 15px;
}
.phone {
    display: none;
}

.subtext {
    font-size: small;
    color: #9c9c9c;
    margin: 5px;
}

.error {
    margin: 0;
    background-color: red;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid red;
    width: 100%;
}

.formError {
    margin: 0;
    background-color: red;
    border-radius: 4px;
    border: 1px solid red;
    padding: 10px;
    width: 100%;
}

.legalLinks {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.legalLink {
    margin: 5px;
    color: #9c9c9c;
    font-size: small;
}

.phoneInput {
    width: 100%;
    margin-top: 15px;
}

.phoneInput .react-phone-number-input__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.phoneInput>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.phoneInput img {
    transform: translate(0, -2px);
}

.phoneInput input, .phoneInput select {
    width: 100%;
    font-size: large;
    border-radius: 5px;
    background-color: #F1F1F1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,0,0,0.05);
    padding: 12px 18px;
}

@media (max-width: 800px) {
    .phone {
        display: block;
    }

    .desktop {
        display: none;
    }
}