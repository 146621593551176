.removedSources {
    background-color: white;
    padding: 10px;
}

.removedSource {
    list-style: none;
    padding: 5px;
    border: 1px black solid;
}

.removedSource:hover {
    background-color: #ffc627;
    cursor: pointer;
}