.table {

    margin: 20px;
}
.totalTable{
    width: 85%;
    margin-bottom: 2%;
}
.globalView{
    color:black;
    display: flex;
flex-direction: row;
    justify-content: center;
}
.white {
    background-color: white;
}
.green {
    background-color: #3aa371;
}
.blue {
    background-color: #dff0ff;
}
.fbSpend{
    color: red;
    font-size: 32px;
    margin-top: 2%;

}

.nestedTable {
    background-color: black;
    width: 100%;
}

.table th {
    padding: 5px 10px;
    margin: 0;
    font-weight: bolder;
    background-color: #dff0ff;
}



.headerRow th {
    font-size: x-large;

}
.headerRow2{
    background-color: white;
    font-size: x-large;
}

.row {
    background-color: white;
    font-size: x-large;
    padding: 5px;
}



.row td {
    font-weight: bold;
    padding: 5px 10px;
    background-color: #f9f8f0;

}

.nestingTd {

}

.nestingRow {
    background-color: white;
}

.nestingHeaderRow th {
    background-color: #9c9c9c;
}

.nestedHeaderRow th {
    background-color: #9c9c9c;
}

.selectable:hover {
    border: 1px solid red;
    background-color: #ffc627;
}

.removeCell {
    background-color: black;
}

.removeIcon {
    color: red;
}

.removeIcon:hover {
    color: #ffc627;
    cursor: pointer;
}

.tinyRow, .nestingHeaderRow {
    font-size: medium;
}

.tinyRow th, .nestingHeaderRow th {
    font-size: medium;
}

@media (max-width: 800px) {
    .table {
        max-width: 100%;
    }

    .row th {
        width: 10%;
    }

    .row td {
        width: 10%;
    }
    .headerRow {
        background-color: #0092D5;
    }
    .headerRow {
        width: 100vw;
    }

    .headerRow th {
        font-size: small;
        max-width: 10%;
    }

    .row {
        background-color: white;
        font-size: small;
        padding: 5px;
    }

    .tinyRow, .nestingHeaderRow {
        font-size: x-small;
    }

    .tinyRow th, .nestingHeaderRow th {
        font-size: x-small;
    }
}