.root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../assets/images/study.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100%;
}

.subRoot {
    box-sizing: border-box;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
}

.headerText{
    font-size: 42px;
    font-weight: bolder;
    padding: 30px;
    box-sizing: border-box;
    margin-bottom: 0;
    color: #0A0A0A;
    width: 80%;
    text-shadow: 0 0 2px white;
}

.reasons {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
}

.reason {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    width: 25%;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

.reasonTitle {
    font-size: x-large;
    margin: 10px 0 20px 0;
}

.reasonBody {
    padding: 0 10px;
    flex-grow: 1;
}

.reasonHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffc627;
}

@media (orientation: portrait) {
    .root {
        background-size: auto 100%;
    }
}

@media (max-width: 1100px) {
    .reasons {
        flex-direction: column;
        align-items: center;
        align-content: center;
    }

    .reason {
        width: 100%;
    }

    .subRoot {
        width: 95%;
    }

    .headerText {
        font-size: xx-large;
    }
}

@media (max-width: 800px) {
    .headerText {
        line-height: 1em;
        width: 95%;
        padding: 0;
        font-size: x-large;
        font-weight: bolder;
    }

    .subRoot {
        width: 100%;
    }

    .reasons {
        border-radius: 0;
        padding-bottom: 20px;
        margin-bottom: 40px;
    }

    .reasonTitle {
        display: none;
    }

    .reason {
        padding-bottom: 5px;
    }

    .icon {
        width: 60px;
        height: 60px;
        margin-bottom: 10px;
    }

    .reasonBody {
        font-weight: bold;
    }
}