.root {
    background-color: black;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 78px;
    padding: 5px 25px;
    z-index: 10;
}

.subRoot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    z-index: 10;
}

.supportText {
    font-size: 20px;
}

@media (max-width: 800px) {
    .root {
        height: auto;

    }

    .subRoot {
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 95%;
    }

    .logoBox {
        margin: 0;
    }

    .supportText {
        margin: 0;
        font-size: small;
    }
}