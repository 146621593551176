.Logo {
    padding: 2px;
    height: 80px;
    box-sizing: border-box;
}

.Logo img {
    height: 100%;
}

@media (max-width: 800px) {
    .Logo {
        height: 40px;
    }
}