.root {
    min-height: 100vh;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: x-large;
}

.button {
    background-color: #0A0A0A;
    border-radius: 4px;
    border: 1px solid #ffc627;
    color: #ffc627;
    padding: 5px 10px;
    font-size: xx-large;
    margin: 10px;
}