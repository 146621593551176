.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.yearlyDealRow {
    display: flex;
    flex-direction: row;
}

.pulseit {
    -webkit-animation: pulse linear 2s infinite;
    animation: pulse linear 2s infinite;
}

[data-braintree-id="card"], [data-braintree-id="paypal"],[data-braintree-id="googlePay"]{
    border: 3px solid red;
}
[id="dropin-container"]{
    min-width: 300px;
}
[data-braintree-id="choose-a-way-to-pay"] {
    font-size: large;
    text-transform: capitalize;
    font-weight: 700;
}


@-webkit-keyframes pulse {
    0% {
        font-size: 19px;
    }
    50% {
        font-size: 20px;
    }
    100% {
        font-size: 19px;
    }
}

@keyframes pulse {
    0% {
        font-size: 19px;
    }
    50% {
        font-size: 20px;
    }
    100% {
        font-size: 19px;
    }
}

.fastTrackBanner {
    background-color: green;
    color: white;
    padding: 5px 20px;
    min-width: 120px;
    font-size: small;
    font-weight: bold;
    transform: translate(20px, -20px) rotate(45deg);
    -webkit-transform: translate(20px, -20px) rotate(45deg);
}

.normalPrice {
    color: red;
}

.normalPrice span {

    text-decoration: line-through;
}

.membershipType {
    font-size: xx-large;
}

.membershipTypeSmall {
    font-size: large;
}

.spinnerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
}

.itemButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.itemButton {
    width: 100%;
    margin: 5px;
    background-color: white;
    font-size: large;
    /*box-sizing: border-box;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 230px;
    position: relative;
    box-shadow: 5px 5px 10px -5px black;
    padding: 10px;
    outline-color: green;
    border: 4px solid red;
    border-radius: 2px;

}

.orOption {
    font-size: x-large;
    font-weight: bold;
    text-decoration: underline;
}

.itemButtonSmall {
    width: 53%;
    margin: 5px;
    background-color: white;
    font-size: large;
    /*box-sizing: border-box;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*
    border: 0 solid white;
    */
    overflow: hidden;
    min-height: 170px;
    position: relative;
    box-shadow: 5px 5px 10px -5px black;
    padding: 10px;
    outline-color: green;
    border: 1px solid grey;
}

.itemButtonSmaller {
    width: 43%;
    margin: 5px;
    background-color: white;
    font-size: large;
    /*box-sizing: border-box;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*
    border: 0 solid white;
    */
    overflow: hidden;
    min-height: 150px;
    position: relative;
    box-shadow: 5px 5px 10px -5px black;
    padding: 10px;
    outline-color: green;
    border: 1px solid grey;
}

.itemButtonText {
    font-weight: bold;
    margin: 10px;
}

.itemButtonTextSmall {
    font-weight: bold;
    margin: 0;
}

.errorItemButton {
    border-color: red;
}

.bestDealText {
    font-size: large;
    font-weight: bold;
    margin-top: 50px;
}

.itemButton:hover {
    color: blue;
    border: 0px solid blue;
    background-color: #d8d8d8;
}

.activeButton {
    border: 0px solid green;
    background-color: #FFFF99;
}

.todaysPrice {
    font-size: xx-large;
}

.todaysPricesmall {
    font-size: large;
}

.fakeButton {
    background-color: black;
    color: white;
    border-radius: 4px;
    padding: 8px 20px;
    font-weight: bold;
    position: absolute;
    bottom: 10px;
}

.popupButton {
    animation: flash 0.5s linear 0s 1 alternate;
}

.check {
    color: green;
    font-size: x-large;
}

.checkHolder {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
}

.mostPopular {
    background-color: #c4c4c4;
    color: white;
    padding: 5px;
    font-size: small;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 0;
}

.creditFormBlock {
    width: 90%;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(221, 221, 221, 0.18);
    border: 1px solid rgb(221, 221, 221);
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.rowBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.columnBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 10px;
    width: 50%;
}

.wide {
    min-width: 60%;
}

.input {
    width: 100%;
    font-size: medium;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    padding: 12px 18px;
    margin-top: 5px;
}

.select {
    width: 100%;
    font-size: medium;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
    margin-top: 5px;
}

.errorInput {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: red;
    box-shadow: none;
}

.formError {
    background-color: red;
    box-sizing: border-box;
    border: 1px solid black;
    padding: 15px;
    border-radius: 1px;
    margin-bottom: 10px;
    width: 100%;
    font-size: large;
    font-weight: bold;
}

.inputError {
    background-color: red;
    width: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 4px;
    box-sizing: border-box;
}

.itemError {
    background-color: red;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 4px;
    border-radius: 4px;
}

.label {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 0;
}

.fastTrackBlock {
    border: 3px dashed black;
    padding: 15px 5px;
    background-color: rgb(252, 248, 227);
    width: 65%;
}

.ftCheckBlock {
    background-color: rgb(255, 255, 153);
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ftCheckRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.ftCheckbox {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
}

.ftCheckbox:hover {
    cursor: pointer;
}

.yearlyCheckLineBoxLeft {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.yearlyCheckLineBoxRight {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.ftCheckLineBoxLeft {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.ftCheckLineBoxRight {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.annualCheckLineBoxRight {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}


.ftCheckLine {
    width: 1px;
    height: 4px;
    background: green;
    transition: width 1s ease;

    -webkit-animation: expand 1s ease-in-out infinite alternate;
    -moz-animation: expand 1s ease-in-out infinite alternate;
    animation: expand 1s ease-in-out infinite alternate;
}

.ftCheckArrowHeadLeft {
    width: 10px;
    height: 10px;
    border-right: 4px solid green;
    border-bottom: 4px solid green;
    transform: rotate(-45deg) translate(-8px, -8px);
    -webkit-transform: rotate(-45deg) translate(-8px, -8px);
}

.ftCheckArrowHeadRight {
    width: 10px;
    height: 10px;
    border-right: 4px solid green;
    border-bottom: 4px solid green;
    transform: rotate(135deg) translate(-8px, -8px);
    -webkit-transform: rotate(135deg) translate(-8px, -8px);
}

.ftCheck {
    color: green;
    font-weight: bolder;
    font-size: larger;
}

.offerTitle {
    font-size: x-large;
    color: green;
    font-weight: 900;
    margin: 0;
    line-height: 30px;
}

.offerText {
    font-size: medium;
}

.offerText b {
    text-decoration: underline;
    color: rgb(204, 51, 0);
}

.submit {
    width: 100%;
    font-size: large;
    font-weight: bolder;
    padding: 13px 35px;
    color: white;
    background-color: #32CD32;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    margin-top: 15px;
}

.submit:hover {
    cursor: pointer;
}

.submit:disabled {
    background-color: #9c9c9c;
    cursor: auto;
}

.safeText {
    font-size: small;
    color: #9c9c9c;
}

.loopTitle {
    font-size: 20px;
    color: green;
    font-weight: bolder;
    width: 75%;
    margin: 0;
}

.loopText {
    font-size: 17px;
    color: green;
    font-weight: bold;
    width: 75%;
    margin: 0;
}

.loopText b {
    font-weight: bolder;
}

.paypalText {
    font-size: medium;
    font-weight: bold;
    color: #9c9c9c;
}

.serverError {
    background-color: red;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 4px;
    font-size: large;
}

.paymentButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}

.paymentButton {
    background-color: rgb(221, 221, 221);
    font-size: large;
    border-radius: 4px;
    padding: 5px 10px;
    box-sizing: border-box;
    width: 50%;
    margin: 5px;
}

.paymentButton:hover {
    color: blue;
    border: 1px solid blue;
    background-color: #f3bb2b;
}

.paypal {
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageBox {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image {
    max-width: 100%;
    max-height: 100px;
}

.bannerBox {
    overflow: hidden;
    width: 100px;
    height: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
}

.bannerBoxLeft {
    overflow: hidden;
    width: 100px;
    height: 100px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
}

.banner {
    background-color: green;
    color: white;
    padding: 5px 20px;
    min-width: 120px;
    font-size: medium;
    font-weight: bold;
    transform: translate(20px, -20px) rotate(45deg);
    -webkit-transform: translate(20px, -20px) rotate(45deg);
}

/* Pulse class and keyframe animation */

.bannerLeft {
    background-color: green;
    color: white;
    padding: 5px 20px;
    min-width: 120px;
    font-size: medium;
    font-weight: bold;
    transform: translate(-20px, -20px) rotate(-45deg);
    -webkit-transform: translate(-20px, -20px) rotate(-45deg);
}

.ribbonBox {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-10deg);
    -webkit-transform: rotate(-10deg);
}

.ribbon {
    max-width: 100%;
    max-height: 100%;
}

.backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 600;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalBox {
    position: fixed;
    width: 30%;
    top: 35%;
    left: 35%;
    z-index: 601;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.modalBox h1 {
    margin: 0;
    line-height: 1.1em;
}

.modalBox button {
    padding: 5px;
    margin: 5px;
    font-size: large;
    border-radius: 5px;
    color: white;
}

.modalYes {
    background-color: green;
    border: 1px solid darkgreen;
    border-bottom: 5px solid darkgreen;
}

.modalYes:hover {
    background-color: #009b00;
}

.modalNo {
    background-color: red;
    border: 1px solid darkred;
    border-bottom: 5px solid darkred;
}

.modalNo:hover {
    background-color: #ff5b27;
}

@-webkit-keyframes glow {
    from {
        border: 1px solid black;
    }
    to {
        border: 2px solid green;
    }
}


@-webkit-keyframes expand {
    from {
        width: 1px;
    }
    to {
        width: 100%;
    }
}

@-webkit-keyframes flash {
    from {
        background-color: rgb(221, 221, 221);
        border-color: black;
        transform: scaleY(0);
    }
    50% {
        background-color: #FFFF99;
        border-color: green;
    }
    to {
        background-color: rgb(221, 221, 221);
        border-color: black;
        transform: scaleY(1);
    }
}

@media (max-width: 800px) {
    .root {
        padding: 5px;
    }

    .membershipType {
        font-size: large;
    }

    .membershipTypeSmall {
        font-size: smaller;
    }

    .todaysPrice {
        font-size: large;
    }

    .todaysPricesmall {
        font-size: medium;
    }

    .fastTrackBlock {
        width: 90%;
    }

    .bestDealText {
        display: none;
    }

    .monthlySub {
        margin: 0;
    }

    .normalPrice {
        font-size: medium;
    }

    .mostPopular {
        display: none;
    }

    .modalBox {
        width: 90%;
        top: 10%;
        left: 5%;
    }

    .modalBox button {
        width: 90%;
    }

    .itemButtons {
        flex-direction: column;
    }

    .itemButton {
        min-height: 160px;
        flex-direction: row;
        width: 100%;
    }

    .itemButtonSmall {
        min-height: 80px;
        flex-direction: row;
        width: 95%;
    }

    .itemButtonTextSmall {
        font-size: smaller;

    }

    .itemButtonText {
        font-size: smaller;
    }

    .itemButtonSmaller {
        min-height: 20px;
        flex-direction: row;
        width: 90%;
    }

    .banner {
        font-size: small;
    }

    .bannerLeft {
        font-size: small;
    }

    .fakeButton {
        display: none;
    }

    .checkHolder {
        position: static;
    }

    .itemButtonText {
        margin: 0 30px;
        transform: translateX(-10px);
    }

    .bannerBox {
        min-width: 80px;
    }

    .loopTitle {
        font-size: 18px;
        margin-bottom: 5px;
        width: 95%;
    }

    .loopText {
        font-size: 14px;
        width: 95%;
    }
}

@media (max-height: 700px) {
    .bannerBox {
        margin-top: 8%;

    }

}