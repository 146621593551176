.root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 3px;
    border: 1px solid black;
    margin: 5px;
    overflow: hidden;
    min-width: 40px;
    min-height: 40px;
}

.icon:hover {
    background-color: #ffc627;
}

.icon {
    min-width: 40px;
    min-height: 40px;
    text-align: center;
}

.textRoot {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
}

.text {
    font-size: larger;
    margin: 0 0 5px 0;
}

.subtext {
    font-size: large;
    font-weight: bold;
}

.mobileButtons {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    display: none;
}

.buttonSubtext {
    display: none;
    font-weight: bold;
    font-size: small;
}

@media (max-width: 800px) {
    .root {
        flex-direction: column;
    }

    .buttonSubtext {
        display: block;
    }

    .nonMobile {
        display: none;
    }

    .mobileButtons {
        display: flex;
        margin-top: 15px;
    }

    .textRoot {
        width: 95%;
    }

    .icon {
        width: 55px;
        height: 55px;
    }

    .button {
        margin: 0 20px;
    }
}