.root {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(245, 245, 245);
}

.container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.left {
    padding: 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.right {
    height: auto;
    padding: 10px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

}

.imageContainer {
    display: flex;
    width: 70%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.imageBox {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid black;
    overflow: hidden;
    margin: 20px;
}

.image {
    max-width: 100%;
    max-height: 100%;
}

.subText {
    width: 48%;
    margin: 0 0 30px 0;
    color: black;
    font-size: x-large;
}

.subTextTwo {
    width: 60%;
    margin: -1% 0 30px 0;
    color: black;
    font-size: x-large;
}

.headingText {
    width: 100%;
    margin-top: 2%;
    font-size: xx-large;
    font-weight: 700;
    text-decoration: underline;
    padding-bottom: 10px;
}

.checks {
    width: 100%;
    font-size: 22px;
    font-weight: 600;
    padding: 5px 14px;
    margin: 5px 0;
    text-align: left;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checks p {
    margin: 0;
    text-align: center;
}

.check {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    max-width: 32px;
    max-height: 32px;
    margin-right: 10px;
    background-color: black;
    color: #ffc627;
    border-radius: 50%;
    font-size: larger;
    font-weight: bold;
}

.ctaButton {
    background-color: #0092D5;
    display: block;
    color: white;
    text-shadow: 1px 1px #0a0810;
    font-size: xx-large;
    font-weight: bolder;
    padding: 10px 25px;
    margin: 30px 0 10px 0;
    border-radius: 3px;
    border: 1px solid rgba(80, 80, 80, 0.97);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
}

.ctaButton:hover {
    cursor: pointer;
    color: #f3bb2b;
}

.ctaButton span {
    font-size: 14px;
    display: block;
    font-weight: normal;
    text-shadow: 1px 1px #0a0810;
}

.mobileBlock {
    display: none;
}

@media (max-width: 800px) {
    .container {
        flex-direction: column;
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    .left {
        padding: 0;
    }

    .right {
        padding: 0 0 40px 0;
        background-color: #dddddd;
        width: 100%;
    }

    .imageContainer {
        flex-direction: row;
        width: 95%;
    }

    .imageBox {
        width: 45%;
        margin: 10px;
        border: none;
        box-shadow: -3px 3px 15px -3px rgba(0, 0, 0, 0.5);
    }

    .subText {
        font-size: 22px;
        width: 95%;
        line-height: 1em;
    }

    .headingText {
        font-size: x-large;
        text-decoration: none;

    }

    .checks {
        font-size: large;
        flex-direction: column;
        width: 85%;
        line-height: 1.1em;
        font-weight: normal;
    }

    .check {
        margin: 0;
    }

    .ctaButton {
        font-size: x-large;
        display: none;
    }

    .mobileBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 20px 0;
    }

    .mobileBanner {
        width: 100%;
        color: #ffc627;
        background-color: black;
        padding: 15px;
        font-size: x-large;
        font-weight: bolder;
        margin: 10px;
        box-sizing: border-box;
    }

    .mobileInfo {
        width: 95%;
        line-height: 1em;
        margin: 10px 0;
    }
}
