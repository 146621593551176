.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    background-image: url("../../assets/images/collagebg.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    background-size: 100% auto;
}
.bannerBox {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.header{
    margin-top: 0;
}
.accessText{
    margin-top: -2%;
    font-weight: bold;
}
.banner {
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.subRoot {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    max-width: 60%;
    margin: 20px;
    border-radius: 8px;
}

.notice {
    width: 100%;
    background-color: rgb(192, 26, 11);
    color: white;
    margin: 0;
    padding: 20px;
    font-size: xx-large;
    font-weight: bolder;
    box-sizing: border-box;
}

.videoBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.videoSubBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 30px;
}

.video {
    margin-bottom: 20px;
}

.button {
    background-color: green;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 32%;
    font-size: x-large;
    border-radius: 8px;
    border: 1px solid mediumseagreen;
    border-bottom: 5px solid darkgreen;
    margin: 10px;
}

.button:hover {
    background-color: #009800;
}

.button:disabled {
    background-color: gray;
    border-color: gray;
}

.checksBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.checksSubBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
}

.checksTitle {
    font-size: x-large;
    margin: 5px 0;
}

.checks {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
}

.check {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.check p {
    margin: 0;
    font-size: x-large;
    text-align: left;
}

.checkIcon {
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    color: green;
}

.regularPrice {
    font-size: xx-large;
    font-weight: bold;
    color: rgb(228, 59, 44);
    margin: 0;
}
.regularPrice span{
    text-decoration: line-through;
}
.offerText {
    font-size: x-large;
    font-weight: bold;
    color: green;
    margin: 0;
}

.offerPrice {
    font-size: xx-large;
    font-weight: bolder;
    color: green;
    margin: 0 0 20px 0;
}

.offerSubtext {
    width: 70%;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white;
    padding-bottom: 20px;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.links a {
    color: #ffc627;
    border: 1px solid #ffc627;
    margin: 5px;
    padding: 5px;
}

.links a:hover {
    background-color: #ffc627;
    color: black;
}

.moneyBackBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}

.guaranteeBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.guaranteeImgBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
}

.guaranteeImg {
    max-height: 100%;
    max-width: 100%;
}

.guaranteeInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.guaranteeTitle {
    font-size: x-large;
    font-weight: bolder;
}

.guaranteeBody {
    font-size: small;
    width: 80%;
}

.encryptedMessage {
    font-size: small;
    width: 50%;
}

.skipButton {
    padding: 10px;
    box-sizing: border-box;
    width: 30%;
    background-color: red;
    border: 1px solid orangered;
    border-bottom: 5px solid darkred;
    border-radius: 8px;
    font-size: large;
    color: white;
}

.skipButton:hover {
    background-color: #ff552f;
}

.desktopButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonRow {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: black;
    position: fixed;
    bottom: 0;
    z-index: 100;
}

.buttonPlaceholder {
    width: 100%;
    height: 100px;
    display: none;
    background-color: black;
}

.creditBlock {
    padding: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    margin: 10px;
    box-sizing: border-box;
    border-radius: 4px;
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    width: 100%;
    position: sticky;
    top: 0;
    font-size: large;
    padding: 5px 10px;
    box-sizing: border-box;
    z-index: 100;
}

@media (max-width: 800px) {
    .subRoot {
        max-width: 95%;
    }

    .creditBlock {
        width: 95%;
    }

    .buttonRow, .buttonPlaceholder {
        display: flex;
    }

    .desktopButton {
        display: none;
    }

    .videoSubBlock {
        width: 100%;
        margin: 0;
    }

    .checksSubBlock {
        width: 100%;
        padding: 10px;
    }

    .notice {
        font-size: large;
    }

    .videoSubBlock h1 {
        font-size: x-large;
    }

    .button {
        width: 50%;
        box-sizing: border-box;
        margin: 10px;
        background-color: green;
        border-bottom: 5px solid darkgreen;
        color: white;
        border-radius: 8px;
        font-size: x-large;
    }

    .skipButton {
        width: 50%;
        box-sizing: border-box;
        margin: 10px;
        background-color: red;
        border-bottom: 5px solid darkred;
        color: white;
        border-radius: 8px;
        font-size: large;
    }

    .checks {
        width: 95%;
    }

    .checksTitle {
        font-size: large;
    }

    .check p {
        font-size: medium;
    }

    .regularPrice, .offerText {
        font-size: x-large;
    }

    .offerSubtext {
        font-size: small;
    }

    .moneyBackBlock {
        margin: 10px 0;
    }
}
@media (max-width: 600px){
    .regularPrice{
        font-size: large;
    }
    .offerText{
        font-size: large;
    }
    .header{
        font-size: large;
    }
    .offerSubtext{
        margin-top: 0;
    }
}
